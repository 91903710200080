export class Constant {
  public static checkoutTimerCount = 360;
  public static source = "Web";
  public static RESPONSE_SUCCESS = "AST-200";
  public static RESPONSE_FAILURE = "AST-102";
  public static USER_FAILURE = "AST-103";
  public static RESPONSE_SUCCESS_VERIFY = "AST-201";
  public static DEFAULT_PAGE_SIZE = 1;
  public static X_XSRF_TOKEN = "cxi";
  public static XSRF_TOKEN = "cci";
  public static DEVICE_TYPE = "MOBILE";
  public static DEVICE = "deviceType";
  public static REFERER = "aerolodge.dspeedup.com";
  public static ENTERPRISE_SITE = "c-ref";
  public static Asc = 'asc';
  public static Dsc = 'desc';
  public static siteName = 'aerolodge'
  public static about = 'AboutUs';
  public static terms = 'terms';
  public static privacy = 'privacy';
  public static shortAbout = 'shortAbout';
  public static CART_COUNT = "ccc";
  public static OTP_TIMER = 10;
  public static CART_ID = "ast";
  public static GUEST_LOGIN:any = "glIn";
  public static USER_INACTIVE_FAILURE = "AST-105";
  public static payment_policy = 'paymentPolicy';
  public static REFERAL_ID:any = "ref";
  public static REFERRED_PRODUCT_ID = "refp";
  public static BANNER_HEIGHT = "bH";
  public static BANNER_WIDTH = "bW";
  public static BANNER_HEIGHT_MOBILE = "bHM";
  public static BANNER_WIDTH_MOBILE = "bWM";
  public static BANNER_HEIGHT_TABLET = "bHT";
  public static BANNER_WIDTH_TABLET = "bWT";
  public static B2B_USER = "gxi";
  public static GOOGLE_ANALYTICS_ENABLED = "gae";
  public static CONVERSION_TRACKING = "cfs";

}

